.container-loader {
  display: flex; // make us of Flexbox
  align-items: center; // does vertically center the desired content
  justify-content: center; // horizontally centers single line items
  text-align: center; // optional, but helps horizontally center text that breaks into multiple lines
  height:75vh;
}

.semantic-Loader {
  vertical-align: middle;
  width: 100%;
  display: inline-block;
  height: 50%;
  position: absolute;
  top: 0;
  bottom: 0%;
  margin: auto;
}

.loader-p {
  position: relative;
  right: 90px;
}