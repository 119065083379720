$orange: #fda94f;

.room-spacing {
  &-card-height{
    height: 433.33px;
  } 
}

.prop-container {
  text-align: center;
  &-card-title {
    color: $orange;
  }
}

.DayPickerInput input {
  height: 48px;
  line-height: 48px;
  width: 100%;
  padding: 0 20px;
  border: 1px solid #D4D4D4;
  border-radius: 4px;
  font-size: 15px;
}
.day-picker {
  &-input {
    background:#f3f3f3!important;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    width: 103%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
  }
  &-popup {
    position: absolute;
  }
}

.grayout {
  opacity: 0.6; /* Real browsers */
  filter: alpha(opacity = 60); /* MSIE */
}

.disabled-link {
  pointer-events: none;
}

.fa-home {
  color: white;
  font-size: 56px;
  position: relative;
  right: 14px;
  bottom: 16px;
}
.fa-times-circle {
  color: white;
  font-size: 56px;
  position: relative;
  right: 12px;
  bottom: 15px;
}

.tour-form {
  &-container {
    height: 550px;
    width: 500px;
    position: fixed;
    background-color: grey;
    right: 50px;
    border-radius: 20px;
    background-color: white;
    z-index: 40;
  }

}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.padding {
  &-bottom {
    &-20 {
      padding-bottom: 20px;
    }
  }
}

.form-error {
  &-message {
    color: red;
    font-size: .8rem;
  }
  &-input {
    border: red solid 1px !important;
    border-radius: 5px;
    input, select, input:hover, select:hover {
      border: none !important;
    }
    overflow: hidden;
  }
}

.amenities {
    &-btn {
    position: absolute;
    bottom: -40px;
    right: 68px;
    margin: 0 auto;
    transform: scale(0.7,0.7);
    font-size: large;
  }
  &-overflow {
    height: 438px;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.scroll {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
    margin-bottom: 10px;
  }
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-button {
    height: 24px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color:$orange;
  }
}

.carousel {
  &-h2 {
    font-size: 16px;
  }
  &-icon-1 {
    padding: 10px 10px 0px 0px;
  }
  &-icon-2 {
    padding: 10px 10px 0px 20px;
  }
  &-ul {
    padding: 2px 0;
  }
}

.padding {
  &-top {
    &-20 {
      padding-top: 39px;
    }
  }
}

.call {
  &-font {
    font-size: 24px;
  }
  &-to-action {
    height: 100px;
  }
  &-to-action-area {
    padding-top: 20px;
    height: 10px !important;
  }
}

.cta-content {
  padding-top: 26px !important;
}

.footer {
  &-icons {
    position: absolute;
    right: 370px;
    bottom: 50px;
  }
  &-top {
    height: 50px !important;
  }
  &-logo {
    position: absolute;
    bottom: -10px;
  }
}

.footer-area.style-two {
  margin-top: 120px !important;
}

.welcome {
  color: white;
  -webkit-text-stroke: 2px black;
  -webkit-font-smoothing: antialiased;
}
.nav-text {
  color: $orange;
  &-bordered {
    color: white !important;
    border-radius: 4px;
    border: 1px solid rgb(0, 0, 0);
    padding: 2px;
    background-color: $orange;
  }
  &:hover {
    color: #666666 !important;
    -webkit-text-stroke: 0.2px black;
    -webkit-font-smoothing: antialiased;
  }
}